export enum FeatureFlag {
  GRAPHICAL_WB = 'AVC_PILOTWEB_GRAPHICAL_WB',
  FLIGHT_ORCHESTRATOR_FLAGS = 'AVC_PILOTWEB_FLIGHT_ORCHESTRATOR_FLAGS',
  INTERNET_TRAFFIC = 'AVC_PILOTWEB_INTERNET_TRAFFIC',
  WB_CARDS = 'AVC_PILOTWEB_WB_CARDS',
  ALTITUDE_SELECTOR = 'AVC_PILOTWEB_ALTITUDE_SELECTOR',
  BETA_ACTIVE = 'AVC_PILOTWEB_BETA',
  BETA_ACTIVE_FOR_USER = 'AVC_PILOTWEB_BETA_USER',
  EIGHT_POINT_PERF = 'AVC_PILOTWEB_8PP',
  MAPBOX_SATELLITE = 'AVC_PILOTWEB_MAPBOX_SATELLITE',
  LOAD_SHEET_MANAGEMENT = 'AVC_PILOTWEB_LOAD_SHEET_MANAGEMENT',
}
