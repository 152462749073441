import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, inject, ElementRef, Directive, Input, Output, HostListener, Injectable, Pipe, NgModule } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, delay } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';
class FlyClickOutsideDirective {
  constructor() {
    this.clickOutside = new EventEmitter();
    this.element = inject(ElementRef);
  }
  onClick(target) {
    if (this.exception != null) {
      const found = this.exception.some(ex => target.classList.contains(ex));
      if (found) {
        return;
      }
    }
    if (!this.isInside(target)) {
      this.clickOutside.emit();
    }
  }
  isInside(elementToCheck) {
    return elementToCheck === this.element.nativeElement || this.element.nativeElement.contains(elementToCheck);
  }
  static {
    this.ɵfac = function FlyClickOutsideDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FlyClickOutsideDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: FlyClickOutsideDirective,
      selectors: [["", "flyClickOutside", ""]],
      hostBindings: function FlyClickOutsideDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function FlyClickOutsideDirective_click_HostBindingHandler($event) {
            return ctx.onClick($event.target);
          }, false, i0.ɵɵresolveDocument);
        }
      },
      inputs: {
        exception: "exception"
      },
      outputs: {
        clickOutside: "clickOutside"
      },
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyClickOutsideDirective, [{
    type: Directive,
    args: [{
      selector: '[flyClickOutside]',
      standalone: false
    }]
  }], null, {
    exception: [{
      type: Input
    }],
    clickOutside: [{
      type: Output
    }],
    onClick: [{
      type: HostListener,
      args: ['document:click', ['$event.target']]
    }]
  });
})();
class Identifiable {
  static {
    this.counter = 0;
  }
  constructor(idPrefix) {
    this.idPrefix = idPrefix;
    this.idPrefix = idPrefix;
  }
  ngOnInit() {
    Identifiable.counter += 1;
    // Generate a unique id if we were passed an empty string.
    if (this.identifier == null || this.identifier.trim().length === 0) {
      this.identifier = `${this.idPrefix || this.constructor.name.replace('Component', '')}-${Identifiable.counter.toString()}`;
    }
  }
  static {
    this.ɵfac = function Identifiable_Factory(__ngFactoryType__) {
      i0.ɵɵinvalidFactory();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: Identifiable
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Identifiable, [{
    type: Directive
  }], () => [{
    type: undefined
  }], null);
})();
class ZIndexService {
  constructor() {
    this.zIndex = 1;
  }
  nextZIndex() {
    this.zIndex += 1;
    return this.zIndex;
  }
  static {
    this.ɵfac = function ZIndexService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ZIndexService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ZIndexService,
      factory: ZIndexService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ZIndexService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
var PopoutPosition;
(function (PopoutPosition) {
  PopoutPosition["ABOVE"] = "above";
  PopoutPosition["BELOW"] = "below";
  PopoutPosition["LEFT"] = "left";
  PopoutPosition["RIGHT"] = "right";
  PopoutPosition["DYNAMIC"] = "dynamic";
  PopoutPosition["DEFAULT"] = "above";
})(PopoutPosition || (PopoutPosition = {}));
class AbstractPopoutComponent {
  constructor(elementRef, zIndexer, renderer, cd) {
    this.elementRef = elementRef;
    this.zIndexer = zIndexer;
    this.renderer = renderer;
    this.cd = cd;
    this.popoutSize = new EventEmitter();
    this.top = 0;
    this.left = 0;
    this.position = PopoutPosition.DEFAULT;
    this.visible = false;
  }
  ngAfterViewInit() {
    const elementChild = this.elementRef.nativeElement.children[0];
    this.renderer.setStyle(elementChild, 'z-index', this.zIndexer.nextZIndex());
    this.popoutSize.emit([elementChild?.offsetWidth, elementChild?.offsetHeight]);
    this.cd.detectChanges();
  }
  static {
    this.ɵfac = function AbstractPopoutComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AbstractPopoutComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(ZIndexService), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AbstractPopoutComponent,
      inputs: {
        templateRef: "templateRef"
      },
      outputs: {
        popoutSize: "popoutSize"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractPopoutComponent, [{
    type: Directive
  }], () => [{
    type: i0.ElementRef
  }, {
    type: ZIndexService
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ChangeDetectorRef
  }], {
    templateRef: [{
      type: Input
    }],
    popoutSize: [{
      type: Output
    }]
  });
})();
const defaultPopout = {
  position: PopoutPosition.DEFAULT,
  topMargin: 8,
  showDelay: 0,
  hideDelay: 0,
  toggle: false
};
class AbstractPopoutDirective {
  constructor(popoutComponentType,
  // Currently no way to infer this, this is the simplest solution atm
  appRef, componentFactoryResolver, elementRef, injector, destroyRef, renderer) {
    this.popoutComponentType = popoutComponentType;
    this.appRef = appRef;
    this.componentFactoryResolver = componentFactoryResolver;
    this.elementRef = elementRef;
    this.injector = injector;
    this.destroyRef = destroyRef;
    this.renderer = renderer;
    this.popOutOptions = defaultPopout;
    this.componentRef = null;
    this.show$ = new Subject();
    this.hide$ = new Subject();
  }
  onMouseMove($event) {
    if (!this.toggle) {
      if (this.componentRef !== null && this.position === PopoutPosition.DYNAMIC) {
        this.componentRef.instance.left = $event.clientX;
        this.componentRef.instance.top = $event.clientY;
        this.setPopoutComponentProperties();
      }
    }
  }
  onMouseEnter() {
    if (!this.toggle) {
      this.initializePopout();
    }
  }
  onMouseLeave() {
    if (!this.toggle) {
      this.hide$.next();
    }
  }
  onClick() {
    if (this.toggle) {
      this.initializePopout();
    }
  }
  onPageClick(targetElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement) || this.componentRef?.location.nativeElement.contains(targetElement);
    if (!clickedInside) {
      if (this.toggle) {
        this.hide$.next();
      }
    }
  }
  onTouchStart($event) {
    $event.preventDefault();
    this.initializePopout();
  }
  onTouchEnd() {
    this.hide$.next();
  }
  resize() {
    if (this.componentRef != null && this.componentRef.instance.visible) {
      this.destroy();
      this.initializePopout();
    }
  }
  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, 'fly-abstract-popout');
    this.hideDelay = this.hideDelay ?? this.popOutOptions.hideDelay;
    this.showDelay = this.showDelay ?? this.popOutOptions.showDelay;
    this.position = this.position ?? this.popOutOptions.position;
    this.topMargin = this.topMargin ?? this.popOutOptions.topMargin;
    this.toggle = this.toggle ?? this.popOutOptions.toggle;
    this.show$.pipe(delay(this.showDelay), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.componentRef != null) {
        this.componentRef.instance.visible = true;
      }
    });
    this.hide$.pipe(delay(this.hideDelay), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.destroy();
    });
  }
  ngOnDestroy() {
    this.destroy();
  }
  destroy() {
    if (this.componentRef != null) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
  initializePopout() {
    if (this.componentRef == null) {
      //ComponentFactoryResolver is deprecated but ViewContainerRef seems to cause issues when using justify-content
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.popoutComponentType);
      this.componentRef = componentFactory.create(this.injector);
      this.appRef.attachView(this.componentRef.hostView);
      const [tooltipDOMElement] = this.componentRef.hostView.rootNodes;
      this.setPopoutComponentProperties();
      this.componentRef.instance.position = this.position;
      this.componentRef.instance.popoutSize.subscribe(([width, height]) => {
        this.setPopoutPosition(this.elementRef.nativeElement.getBoundingClientRect(), width, height, this.position);
        this.show$.next();
      });
      document.body.appendChild(tooltipDOMElement);
    }
  }
  setPositionBelow(rect) {
    if (this.componentRef != null) {
      const left = rect.left;
      const bottom = rect.bottom;
      const width = rect.width;
      this.componentRef.instance.left = Math.round(width / 2 + left);
      this.componentRef.instance.top = Math.round(bottom);
    }
  }
  setPositionAbove(rect) {
    if (this.componentRef != null) {
      const left = rect.left;
      const top = rect.top;
      const width = rect.width;
      this.componentRef.instance.left = Math.round(width / 2 + left);
      this.componentRef.instance.top = Math.round(top - this.topMargin);
    }
  }
  setPositionLeft(rect) {
    if (this.componentRef != null) {
      const left = rect.left;
      const top = rect.top;
      const height = rect.height;
      this.componentRef.instance.left = Math.round(left);
      this.componentRef.instance.top = Math.round(top + height / 2);
    }
  }
  setPositionRight(rect) {
    if (this.componentRef != null) {
      const top = rect.top;
      const right = rect.right;
      const height = rect.height;
      this.componentRef.instance.left = Math.round(right);
      this.componentRef.instance.top = Math.round(top + height / 2);
    }
  }
  setPopoutPosition(rect, popoutWidth, popoutHeight, position, attemptCount = 0) {
    if (this.componentRef != null && attemptCount < 4) {
      const left = rect.left;
      const top = rect.top;
      const right = rect.right;
      const bottom = rect.bottom;
      switch (position) {
        case PopoutPosition.BELOW:
          if (bottom + popoutHeight < window.innerHeight && left - popoutWidth > 0 && right + popoutWidth < window.innerWidth) {
            this.setPositionBelow(rect);
          } else {
            this.componentRef.instance.position = PopoutPosition.LEFT;
            this.setPopoutPosition(rect, popoutWidth, popoutHeight, PopoutPosition.LEFT, attemptCount + 1);
          }
          break;
        case PopoutPosition.ABOVE:
          if (top - popoutHeight > 0 && left - popoutWidth > 0 && right + popoutWidth < window.innerWidth) {
            this.setPositionAbove(rect);
          } else {
            this.componentRef.instance.position = PopoutPosition.RIGHT;
            this.setPopoutPosition(rect, popoutWidth, popoutHeight, PopoutPosition.RIGHT, attemptCount + 1);
          }
          break;
        case PopoutPosition.LEFT:
          if (left - popoutWidth > 0 && bottom + popoutHeight < window.innerHeight && top - popoutHeight > 0) {
            this.setPositionLeft(rect);
          } else {
            this.componentRef.instance.position = PopoutPosition.ABOVE;
            this.setPopoutPosition(rect, popoutWidth, popoutHeight, PopoutPosition.ABOVE, attemptCount + 1);
          }
          break;
        case PopoutPosition.RIGHT:
          if (right + popoutWidth < window.innerWidth && bottom + popoutHeight < window.innerHeight && top - popoutHeight > 0) {
            this.setPositionRight(rect);
          } else {
            this.componentRef.instance.position = PopoutPosition.BELOW;
            this.setPopoutPosition(rect, popoutWidth, popoutHeight, PopoutPosition.BELOW, attemptCount + 1);
          }
          break;
        default:
          break;
      }
    }
  }
  static {
    this.ɵfac = function AbstractPopoutDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AbstractPopoutDirective)(i0.ɵɵdirectiveInject(i0.Type), i0.ɵɵdirectiveInject(i0.ApplicationRef), i0.ɵɵdirectiveInject(i0.ComponentFactoryResolver), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.DestroyRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AbstractPopoutDirective,
      hostBindings: function AbstractPopoutDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mousemove", function AbstractPopoutDirective_mousemove_HostBindingHandler($event) {
            return ctx.onMouseMove($event);
          })("mouseenter", function AbstractPopoutDirective_mouseenter_HostBindingHandler() {
            return ctx.onMouseEnter();
          })("mouseleave", function AbstractPopoutDirective_mouseleave_HostBindingHandler() {
            return ctx.onMouseLeave();
          })("click", function AbstractPopoutDirective_click_HostBindingHandler($event) {
            return ctx.onClick($event);
          })("click", function AbstractPopoutDirective_click_HostBindingHandler($event) {
            return ctx.onPageClick($event.target);
          }, false, i0.ɵɵresolveDocument)("touchstart", function AbstractPopoutDirective_touchstart_HostBindingHandler($event) {
            return ctx.onTouchStart($event);
          })("touchend", function AbstractPopoutDirective_touchend_HostBindingHandler() {
            return ctx.onTouchEnd();
          })("resize", function AbstractPopoutDirective_resize_HostBindingHandler() {
            return ctx.resize();
          }, false, i0.ɵɵresolveWindow);
        }
      },
      inputs: {
        hideDelay: "hideDelay",
        showDelay: "showDelay",
        position: "position",
        topMargin: "topMargin",
        toggle: "toggle",
        popOutOptions: "popOutOptions"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractPopoutDirective, [{
    type: Directive
  }], () => [{
    type: i0.Type
  }, {
    type: i0.ApplicationRef
  }, {
    type: i0.ComponentFactoryResolver
  }, {
    type: i0.ElementRef
  }, {
    type: i0.Injector
  }, {
    type: i0.DestroyRef
  }, {
    type: i0.Renderer2
  }], {
    hideDelay: [{
      type: Input
    }],
    showDelay: [{
      type: Input
    }],
    position: [{
      type: Input
    }],
    topMargin: [{
      type: Input
    }],
    toggle: [{
      type: Input
    }],
    popOutOptions: [{
      type: Input
    }],
    onMouseMove: [{
      type: HostListener,
      args: ['mousemove', ['$event']]
    }],
    onMouseEnter: [{
      type: HostListener,
      args: ['mouseenter']
    }],
    onMouseLeave: [{
      type: HostListener,
      args: ['mouseleave']
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }],
    onPageClick: [{
      type: HostListener,
      args: ['document:click', ['$event.target']]
    }],
    onTouchStart: [{
      type: HostListener,
      args: ['touchstart', ['$event']]
    }],
    onTouchEnd: [{
      type: HostListener,
      args: ['touchend']
    }],
    resize: [{
      type: HostListener,
      args: ['window:resize']
    }]
  });
})();
class FlyValidationErrorsPipe {
  transform(validationErrors, errorMap, cautionMap) {
    if (validationErrors == null || Object.keys(validationErrors).length === 0) {
      return null;
    }
    if (validationErrors.errors != null) {
      const errorMessages = Object.keys(validationErrors.errors).filter(key => validationErrors.errors[key]) // Filter out all keys with falsy values.
      .filter(key => errorMap.hasOwnProperty(key)).map(key => {
        const message = errorMap[key];
        return typeof message === 'function' ? message(validationErrors.errors[key]) // Pass the ValidationError value to the error string function.
        : message; // Otherwise, return the error string itself.
      });
      if (errorMessages.length > 0) {
        return {
          type: 'error',
          messages: errorMessages
        };
      }
    } else if (validationErrors.cautions != null && cautionMap != null) {
      const cautionMessages = Object.keys(validationErrors.cautions).filter(key => validationErrors.cautions[key]).filter(key => cautionMap.hasOwnProperty(key)).map(key => {
        const message = cautionMap[key];
        return typeof message === 'function' ? message(validationErrors.cautions[key]) // Pass the ValidationError value to the error string function.
        : message; // Otherwise, return the error string itself.
      });
      if (cautionMessages.length > 0) {
        return {
          type: 'caution',
          messages: cautionMessages
        };
      }
    }
    return null;
  }
  static {
    this.ɵfac = function FlyValidationErrorsPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FlyValidationErrorsPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "flyValidationErrors",
      type: FlyValidationErrorsPipe,
      pure: true,
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyValidationErrorsPipe, [{
    type: Pipe,
    args: [{
      name: 'flyValidationErrors',
      standalone: false
    }]
  }], null, null);
})();
class FlySharedModule {
  static {
    this.ɵfac = function FlySharedModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FlySharedModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FlySharedModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlySharedModule, [{
    type: NgModule,
    args: [{
      declarations: [FlyClickOutsideDirective, FlyValidationErrorsPipe],
      imports: [CommonModule],
      exports: [FlyClickOutsideDirective, FlyValidationErrorsPipe]
    }]
  }], null, null);
})();
var FlyFontSizing;
(function (FlyFontSizing) {
  FlyFontSizing["Small"] = "small";
  FlyFontSizing["Medium"] = "medium";
})(FlyFontSizing || (FlyFontSizing = {}));
var FlySizing;
(function (FlySizing) {
  FlySizing["Medium"] = "medium";
  FlySizing["Large"] = "large";
})(FlySizing || (FlySizing = {}));
const OpenCloseAnimation = [trigger('openClose', [state('void', style({
  opacity: 0,
  transformOrigin: 'top',
  transform: 'scaleY(0)'
})), transition('void => open', animate('100ms ease-in', style({
  opacity: 1,
  transform: 'scaleY(1)'
}))), transition('* => void', animate('100ms linear', style({
  opacity: 0
})))])];

/*
 * Public API Surface of avcloud-ui-common/shared
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AbstractPopoutComponent, AbstractPopoutDirective, FlyClickOutsideDirective, FlyFontSizing, FlySharedModule, FlySizing, FlyValidationErrorsPipe, Identifiable, OpenCloseAnimation, PopoutPosition, ZIndexService, defaultPopout };
