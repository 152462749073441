var _jsonToScss$1 = {
  suffix: " !default; // generated from _breakpoints.json, do not modify here"
};
var BREAKPOINTS = {
  _jsonToScss: _jsonToScss$1,
  "md-lg-breakpoint": "1024px",
  "sm-md-breakpoint": "928px",
  "sm-breakpoint": "480px"
};
const SM_MD_BREAKPOINT = BREAKPOINTS['sm-md-breakpoint'];
const MD_LG_BREAKPOINT = BREAKPOINTS['md-lg-breakpoint'];
// Object instead of enums because enums don't do computed values.
const Breakpoints = {
  SmallScreen: `(max-width: ${SM_MD_BREAKPOINT})`,
  MediumScreen: `(min-width: ${SM_MD_BREAKPOINT}) and (max-width: ${MD_LG_BREAKPOINT})`,
  LargeScreen: `(min-width: ${MD_LG_BREAKPOINT})`,
  SmallScreenMaxWidth: `(max-width: ${SM_MD_BREAKPOINT})`,
  MediumScreenMinWidth: `(min-width: ${SM_MD_BREAKPOINT})`,
  MediumScreenMaxWidth: `(max-width: ${MD_LG_BREAKPOINT})`,
  LargeScreenMinWidth: `(min-width: ${MD_LG_BREAKPOINT})`
};
var _jsonToScss = {
  suffix: " !default; // generated from _common.json, do not modify here"
};
var transparent = "#0000";
var blue = "#0066f5";
var green = "#119637";
var yellow = "#f1c21b";
var orange = "#ffa500";
var magenta = "#ff00ff";
var cyan = "#00ffff";
var red = "#da1e28";
var black = "#000000";
var white = "#ffffff";
var COMMON = {
  _jsonToScss: _jsonToScss,
  transparent: transparent,
  blue: blue,
  "blue-logo": "#047cc1",
  "blue-0": "#000023",
  "blue-10": "#000349",
  "blue-20": "#000e70",
  "blue-30": "#001f96",
  "blue-40": "#0033b9",
  "blue-50": "#0049d6",
  "blue-60": "#0062ef",
  "blue-70": "#297bff",
  "blue-80": "#4d95ff",
  "blue-90": "#72aeff",
  "blue-95": "#98c7ff",
  "blue-99": "#c1deff",
  "blue-100": "#ebf5ff",
  green: green,
  "green-0": "#000900",
  "green-10": "#001c00",
  "green-20": "#003100",
  "green-30": "#004600",
  "green-40": "#005c00",
  "green-50": "#00720b",
  "green-60": "#008728",
  "green-70": "#2d9c44",
  "green-80": "#55b062",
  "green-90": "#7ac482",
  "green-95": "#9fd6a4",
  "green-99": "#c6e7c8",
  "green-100": "#edf8ed",
  yellow: yellow,
  "yellow-0": "#0d0300",
  "yellow-10": "#220d00",
  "yellow-20": "#391c00",
  "yellow-30": "#502c00",
  "yellow-40": "#673e00",
  "yellow-50": "#7e5200",
  "yellow-60": "#936700",
  "yellow-70": "#a77d00",
  "yellow-80": "#ba9404",
  "yellow-90": "#cbac51",
  "yellow-95": "#dbc484",
  "yellow-99": "#eadcb5",
  "yellow-100": "#f8f4e7",
  orange: orange,
  "orange-0": "#110100",
  "orange-10": "#2b0400",
  "orange-20": "#450e00",
  "orange-30": "#601c00",
  "orange-40": "#7a2d00",
  "orange-50": "#934100",
  "orange-60": "#aa5600",
  "orange-70": "#be6e00",
  "orange-80": "#d08711",
  "orange-90": "#dfa154",
  "orange-95": "#ebbc85",
  "orange-99": "#f5d7b6",
  "orange-100": "#fcf2e8",
  magenta: magenta,
  "magenta-0": "#170019",
  "magenta-10": "#340038",
  "magenta-20": "#530058",
  "magenta-30": "#720077",
  "magenta-40": "#900095",
  "magenta-50": "#ac00b0",
  "magenta-60": "#c500c7",
  "magenta-70": "#da00db",
  "magenta-80": "#eb3eea",
  "magenta-90": "#f870f5",
  "magenta-95": "#ff9bfc",
  "magenta-99": "#ffc5ff",
  "magenta-100": "#ffedff",
  cyan: cyan,
  "cyan-0": "#00090b",
  "cyan-10": "#001c1e",
  "cyan-20": "#003134",
  "cyan-30": "#004649",
  "cyan-40": "#005c5f",
  "cyan-50": "#007275",
  "cyan-60": "#00888a",
  "cyan-70": "#009d9e",
  "cyan-80": "#00b1b2",
  "cyan-90": "#48c4c4",
  "cyan-95": "#84d7d6",
  "cyan-99": "#b7e8e7",
  red: red,
  "red-0": "#190000",
  "red-10": "#380000",
  "red-20": "#590000",
  "red-30": "#7a0000",
  "red-40": "#990000",
  "red-50": "#b60000",
  "red-60": "#cf0c1f",
  "red-70": "#e43d3c",
  "red-80": "#f5625b",
  "red-90": "#ff857c",
  "red-95": "#ffa89f",
  "red-99": "#ffccc5",
  "red-100": "#ffefec",
  "cyan-100": "#e9f8f7",
  black: black,
  "black-80p": "rgba(0, 0, 0, 0.8000)",
  "black-70p": "rgba(0, 0, 0, 0.7000)",
  "grey-0": "#050505",
  "grey-0-90p": "rgba(5, 5, 5, 0.9000)",
  "grey-10": "#131313",
  "grey-12": "#171717",
  "grey-10-90p": "rgba(19, 19, 19, 0.9000)",
  "grey-20": "#232323",
  "grey-20-90p": "rgba(35, 35, 35, 0.9000)",
  "grey-30": "#353535",
  "grey-40": "#474747",
  "grey-50": "#5b5b5b",
  "grey-60": "#6f6f6f",
  "grey-70": "#848484",
  "grey-80": "#999999",
  "grey-90": "#afafaf",
  "grey-95": "#c5c5c5",
  "grey-99": "#dcdcdc",
  "grey-99-90p": "rgba(220, 220, 220, 0.9000)",
  "grey-100": "#f4f4f4",
  "grey-100-90p": "rgba(244, 244, 244, 0.9000)",
  white: white,
  "white-90p": "rgba(255, 255, 255, 0.9000)",
  "spacing-01": ".125rem",
  "spacing-02": ".25rem",
  "spacing-03": ".5rem",
  "spacing-04": ".75rem",
  "spacing-05": "1rem",
  "spacing-06": "1.5rem",
  "spacing-07": "2rem",
  "spacing-08": "2.5rem",
  "spacing-09": "3rem",
  "spacing-10": "4rem",
  "spacing-11": "5rem",
  "spacing-12": "6rem",
  "spacing-13": "10rem",
  "font-family": "roboto",
  "font-weight-lighter": "300",
  "font-weight-regular": "400",
  "font-weight-semibold": "600",
  "font-size-h1": "2.75rem",
  "font-size-h2": "2rem",
  "font-size-h3": "1.625rem",
  "font-size-h4": "1.375rem",
  "font-size-h5": "1.125rem",
  "font-size-h6": "1rem",
  "font-size-body-1": ".875rem",
  "font-size-body-2": ".75rem",
  "font-size-body-3": ".625rem",
  "font-size-footer": ".625rem",
  "font-size-input-label": ".75rem",
  "font-line-height-h1": "2.5625rem",
  "font-line-height-h2": "2.125rem",
  "font-line-height-h3": "1.75rem",
  "font-line-height-h4": "1.5625rem",
  "font-line-height-h5": "1.5625rem",
  "font-line-height-h6": "1.438rem",
  "font-line-height-body-1": "1.313rem",
  "font-line-height-body-2": "1.25rem",
  "font-line-height-body-3": "1rem",
  "font-line-height-input-label": "1.125rem",
  "font-line-height-overline-label": "1.125rem",
  "font-line-height-uom": ".75rem"
};

/* eslint-disable @typescript-eslint/dot-notation */
const TRANSPARENT = COMMON['transparent'];
const BLUE = COMMON['blue'];
const BLUE_LOGO = COMMON['blue-logo'];
const BLUE_0 = COMMON['blue-0'];
const BLUE_10 = COMMON['blue-10'];
const BLUE_20 = COMMON['blue-20'];
const BLUE_30 = COMMON['blue-30'];
const BLUE_40 = COMMON['blue-40'];
const BLUE_50 = COMMON['blue-50'];
const BLUE_60 = COMMON['blue-60'];
const BLUE_70 = COMMON['blue-70'];
const BLUE_80 = COMMON['blue-80'];
const BLUE_90 = COMMON['blue-90'];
const BLUE_95 = COMMON['blue-95'];
const BLUE_99 = COMMON['blue-99'];
const BLUE_100 = COMMON['blue-100'];
const GREEN = COMMON['green'];
const GREEN_0 = COMMON['green-0'];
const GREEN_10 = COMMON['green-10'];
const GREEN_20 = COMMON['green-20'];
const GREEN_30 = COMMON['green-30'];
const GREEN_40 = COMMON['green-40'];
const GREEN_50 = COMMON['green-50'];
const GREEN_60 = COMMON['green-60'];
const GREEN_70 = COMMON['green-70'];
const GREEN_80 = COMMON['green-80'];
const GREEN_90 = COMMON['green-90'];
const GREEN_95 = COMMON['green-95'];
const GREEN_99 = COMMON['green-99'];
const GREEN_100 = COMMON['green-100'];
const YELLOW = COMMON['yellow'];
const YELLOW_0 = COMMON['yellow-0'];
const YELLOW_10 = COMMON['yellow-10'];
const YELLOW_20 = COMMON['yellow-20'];
const YELLOW_30 = COMMON['yellow-30'];
const YELLOW_40 = COMMON['yellow-40'];
const YELLOW_50 = COMMON['yellow-50'];
const YELLOW_60 = COMMON['yellow-60'];
const YELLOW_70 = COMMON['yellow-70'];
const YELLOW_80 = COMMON['yellow-80'];
const YELLOW_90 = COMMON['yellow-90'];
const YELLOW_95 = COMMON['yellow-95'];
const YELLOW_99 = COMMON['yellow-99'];
const YELLOW_100 = COMMON['yellow-100'];
const ORANGE = COMMON['orange'];
const ORANGE_0 = COMMON['orange-0'];
const ORANGE_10 = COMMON['orange-10'];
const ORANGE_20 = COMMON['orange-20'];
const ORANGE_30 = COMMON['orange-30'];
const ORANGE_40 = COMMON['orange-40'];
const ORANGE_50 = COMMON['orange-50'];
const ORANGE_60 = COMMON['orange-60'];
const ORANGE_70 = COMMON['orange-70'];
const ORANGE_80 = COMMON['orange-80'];
const ORANGE_90 = COMMON['orange-90'];
const ORANGE_95 = COMMON['orange-95'];
const ORANGE_99 = COMMON['orange-99'];
const ORANGE_100 = COMMON['orange-100'];
const MAGENTA = COMMON['magenta'];
const MAGENTA_0 = COMMON['magenta-0'];
const MAGENTA_10 = COMMON['magenta-10'];
const MAGENTA_20 = COMMON['magenta-20'];
const MAGENTA_30 = COMMON['magenta-30'];
const MAGENTA_40 = COMMON['magenta-40'];
const MAGENTA_50 = COMMON['magenta-50'];
const MAGENTA_60 = COMMON['magenta-60'];
const MAGENTA_70 = COMMON['magenta-70'];
const MAGENTA_80 = COMMON['magenta-80'];
const MAGENTA_90 = COMMON['magenta-90'];
const MAGENTA_95 = COMMON['magenta-95'];
const MAGENTA_99 = COMMON['magenta-99'];
const MAGENTA_100 = COMMON['magenta-100'];
const CYAN = COMMON['cyan'];
const CYAN_0 = COMMON['cyan-0'];
const CYAN_10 = COMMON['cyan-10'];
const CYAN_20 = COMMON['cyan-20'];
const CYAN_30 = COMMON['cyan-30'];
const CYAN_40 = COMMON['cyan-40'];
const CYAN_50 = COMMON['cyan-50'];
const CYAN_60 = COMMON['cyan-60'];
const CYAN_70 = COMMON['cyan-70'];
const CYAN_80 = COMMON['cyan-80'];
const CYAN_90 = COMMON['cyan-90'];
const CYAN_95 = COMMON['cyan-95'];
const CYAN_99 = COMMON['cyan-99'];
const RED = COMMON['red'];
const RED_0 = COMMON['red-0'];
const RED_10 = COMMON['red-10'];
const RED_20 = COMMON['red-20'];
const RED_30 = COMMON['red-30'];
const RED_40 = COMMON['red-40'];
const RED_50 = COMMON['red-50'];
const RED_60 = COMMON['red-60'];
const RED_70 = COMMON['red-70'];
const RED_80 = COMMON['red-80'];
const RED_90 = COMMON['red-90'];
const RED_95 = COMMON['red-95'];
const RED_99 = COMMON['red-99'];
const RED_100 = COMMON['red-100'];
const CYAN_100 = COMMON['cyan-100'];
const BLACK = COMMON['black'];
const BLACK_80P = COMMON['black-80p'];
const BLACK_70P = COMMON['black-70p'];
const GREY_0 = COMMON['grey-0'];
const GREY_0_90P = COMMON['grey-0-90p'];
const GREY_10 = COMMON['grey-10'];
const GREY_12 = COMMON['grey-12'];
const GREY_10_90P = COMMON['grey-10-90p'];
const GREY_20 = COMMON['grey-20'];
const GREY_20_90P = COMMON['grey-20-90p'];
const GREY_30 = COMMON['grey-30'];
const GREY_40 = COMMON['grey-40'];
const GREY_50 = COMMON['grey-50'];
const GREY_60 = COMMON['grey-60'];
const GREY_70 = COMMON['grey-70'];
const GREY_80 = COMMON['grey-80'];
const GREY_90 = COMMON['grey-90'];
const GREY_95 = COMMON['grey-95'];
const GREY_99 = COMMON['grey-99'];
const GREY_99_90P = COMMON['grey-99-90p'];
const GREY_100 = COMMON['grey-100'];
const GREY_100_90P = COMMON['grey-100-90p'];
const WHITE = COMMON['white'];
const WHITE_90P = COMMON['white-90p'];
const SPACING_01 = COMMON['spacing-01'];
const SPACING_02 = COMMON['spacing-02'];
const SPACING_03 = COMMON['spacing-03'];
const SPACING_04 = COMMON['spacing-04'];
const SPACING_05 = COMMON['spacing-05'];
const SPACING_06 = COMMON['spacing-06'];
const SPACING_07 = COMMON['spacing-07'];
const SPACING_08 = COMMON['spacing-08'];
const SPACING_09 = COMMON['spacing-09'];
const SPACING_10 = COMMON['spacing-10'];
const SPACING_11 = COMMON['spacing-11'];
const SPACING_12 = COMMON['spacing-12'];
const SPACING_13 = COMMON['spacing-13'];
const FONT_FAMILY = COMMON['font-family'];
const FONT_WEIGHT_REGULAR = COMMON['font-weight-regular'];
const FONT_WEIGHT_SEMIBOLD = COMMON['font-weight-semibold'];
const FONT_SIZE_H1 = COMMON['font-size-h1'];
const FONT_SIZE_H2 = COMMON['font-size-h2'];
const FONT_SIZE_H3 = COMMON['font-size-h3'];
const FONT_SIZE_H4 = COMMON['font-size-h4'];
const FONT_SIZE_H5 = COMMON['font-size-h5'];
const FONT_SIZE_H6 = COMMON['font-size-h6'];
const FONT_SIZE_BODY_1 = COMMON['font-size-body-1'];
const FONT_SIZE_BODY_2 = COMMON['font-size-body-2'];
const FONT_SIZE_BODY_3 = COMMON['font-size-body-3'];
const FONT_LINE_HEIGHT_H1 = COMMON['font-line-height-h1'];
const FONT_LINE_HEIGHT_H2 = COMMON['font-line-height-h2'];
const FONT_LINE_HEIGHT_H3 = COMMON['font-line-height-h3'];
const FONT_LINE_HEIGHT_H4 = COMMON['font-line-height-h4'];
const FONT_LINE_HEIGHT_H5 = COMMON['font-line-height-h5'];
const FONT_LINE_HEIGHT_H6 = COMMON['font-line-height-h6'];
const FONT_LINE_HEIGHT_BODY_1 = COMMON['font-line-height-body-1'];
const FONT_LINE_HEIGHT_BODY_2 = COMMON['font-line-height-body-2'];
const FONT_LINE_HEIGHT_BODY_3 = COMMON['font-line-height-body-3'];
const FONT_LINE_HEIGHT_OVERLINE_LABEL = COMMON['font-line-height-overline-label'];
var Theme;
(function (Theme) {
  Theme["Dark"] = "theme-dark";
  Theme["Gray"] = "theme-gray";
})(Theme || (Theme = {}));

/*
 * Public API Surface of avcloud-ui-common/style-variables
 */

/**
 * Generated bundle index. Do not edit.
 */

export { BLACK, BLACK_70P, BLACK_80P, BLUE, BLUE_0, BLUE_10, BLUE_100, BLUE_20, BLUE_30, BLUE_40, BLUE_50, BLUE_60, BLUE_70, BLUE_80, BLUE_90, BLUE_95, BLUE_99, BLUE_LOGO, Breakpoints, CYAN, CYAN_0, CYAN_10, CYAN_100, CYAN_20, CYAN_30, CYAN_40, CYAN_50, CYAN_60, CYAN_70, CYAN_80, CYAN_90, CYAN_95, CYAN_99, FONT_FAMILY, FONT_LINE_HEIGHT_BODY_1, FONT_LINE_HEIGHT_BODY_2, FONT_LINE_HEIGHT_BODY_3, FONT_LINE_HEIGHT_H1, FONT_LINE_HEIGHT_H2, FONT_LINE_HEIGHT_H3, FONT_LINE_HEIGHT_H4, FONT_LINE_HEIGHT_H5, FONT_LINE_HEIGHT_H6, FONT_LINE_HEIGHT_OVERLINE_LABEL, FONT_SIZE_BODY_1, FONT_SIZE_BODY_2, FONT_SIZE_BODY_3, FONT_SIZE_H1, FONT_SIZE_H2, FONT_SIZE_H3, FONT_SIZE_H4, FONT_SIZE_H5, FONT_SIZE_H6, FONT_WEIGHT_REGULAR, FONT_WEIGHT_SEMIBOLD, GREEN, GREEN_0, GREEN_10, GREEN_100, GREEN_20, GREEN_30, GREEN_40, GREEN_50, GREEN_60, GREEN_70, GREEN_80, GREEN_90, GREEN_95, GREEN_99, GREY_0, GREY_0_90P, GREY_10, GREY_100, GREY_100_90P, GREY_10_90P, GREY_12, GREY_20, GREY_20_90P, GREY_30, GREY_40, GREY_50, GREY_60, GREY_70, GREY_80, GREY_90, GREY_95, GREY_99, GREY_99_90P, MAGENTA, MAGENTA_0, MAGENTA_10, MAGENTA_100, MAGENTA_20, MAGENTA_30, MAGENTA_40, MAGENTA_50, MAGENTA_60, MAGENTA_70, MAGENTA_80, MAGENTA_90, MAGENTA_95, MAGENTA_99, ORANGE, ORANGE_0, ORANGE_10, ORANGE_100, ORANGE_20, ORANGE_30, ORANGE_40, ORANGE_50, ORANGE_60, ORANGE_70, ORANGE_80, ORANGE_90, ORANGE_95, ORANGE_99, RED, RED_0, RED_10, RED_100, RED_20, RED_30, RED_40, RED_50, RED_60, RED_70, RED_80, RED_90, RED_95, RED_99, SPACING_01, SPACING_02, SPACING_03, SPACING_04, SPACING_05, SPACING_06, SPACING_07, SPACING_08, SPACING_09, SPACING_10, SPACING_11, SPACING_12, SPACING_13, TRANSPARENT, Theme, WHITE, WHITE_90P, YELLOW, YELLOW_0, YELLOW_10, YELLOW_100, YELLOW_20, YELLOW_30, YELLOW_40, YELLOW_50, YELLOW_60, YELLOW_70, YELLOW_80, YELLOW_90, YELLOW_95, YELLOW_99 };
